@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@600&display=swap);
/* .slideImage {
  width: 100%;
  height: 450px;
}
.channelCard {
  border-radius: 150px !important;
}
.channelImg {
  border-radius: 150px !important;
}
.channelTitle{
font-size: 12px;
font-weight: 450;
text-align: center;
} */

/* Home Sections CSS */

body * {
  margin: 0;
}
.font-face-bw-reg{
  font-family: "BwModelica";
}
.font-face-bw-bold{
  font-family: "BwModelica-Bold";
}
/* Footer Css*/
html, body {
  /* max-width: 100%; */
  /* overflow-x: hidden; */
}
.footer-nav i{margin-top: 12px; font-size: 14px;}
.footer-nav a, p {
  color: #606060;
  text-align: center;
  font-size: 11px;
  padding: 0px 0 5px 0px;
}
.main{
  position: relative;
  overflow: scroll;
  /* margin-bottom: %; */
}
.nav-item{
  text-align: center;
  display: flex;
 width: 100px;

}
/* .footer-nav i {
  font-size: 20px;
} */
.footer-nav a, i{
  color: deeppink;
}

i.fa-play-circle, .fa-pause-circle{
  font-size: 20px;
}
body * {
    margin: 0;
    font-family: 'BwModelica';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.contact-section a {color: #eb1163 !important; }
.likeCountNo{
    text-align: center;
    padding-bottom: 12px;
}
.contact-section{padding: 40px 0;}
.contact-text a{color: #eb1163;}
.contact-text p{ font-size: 16px; line-height: 28px;}
.contact-title{font-size: 34px; font-weight: 900; padding-bottom: 30px;}
.likeCountImg{
  width: 20px;
    padding: 5px 0;
    margin: auto;
    display: block;
}
.likeCounts{
    position: absolute;
    bottom: 2vh;
    right: 4vw;
    
}
.subscribedImg{
    width: 15px;
   
}
.isSubscribed{
    cursor: pointer;
}
.isNotSubscribed{
    /* width: 40px; */
}
.subscribeImg{
    width: 60px;
    margin-right: -5px;
    margin-top: -4px;    
}
.screenbg{
    background-color:#fde6ef;
    padding: 60px 0px;
}
.mobiscreen{width:300px; display: block; margin: auto;}
code {
    font-family: 'Mukta', sans-serif;
}
/* App lnguage popup */
.profile-body{ margin-top: 20px;}
.lang-title{
    padding: 15px 20px;
    font-size: 21px;   
}
.lang-pink-btn button{
    background-color: #eb1163;
    border-radius: 35px;
    color: white;
}
/* App lnguage popup */
.lang-btn button {
    border-radius: 45px;
    border: 1px solid #eb1163;
    background: white;
    color: #eb1163;
    box-shadow: 0 0 black;
}
.lang-btn-filter button{
    border-radius: 45px;
    border: 1px solid white;
    background-color: white;
    padding: 4px 20px;
    box-shadow: 3px 5px 7px #ccc;
}
.lang-card .confirm-btn{
    border-radius: 45px;
    border: 1px solid #eb1163;
    background: #eb1163;
    color: white;
    box-shadow: 0 0 black;
    border-radius:35px !important;
}

/* .lang-btn button.active, .lang-btn button:hover, .lang-btn button:focus{
    background: #eb1163 !important;
    color: white !important;
    border: 1px solid #eb1163;
} */
/* .lang-btn button.active,.lang-btn button:hover .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background: #eb1163 !important;
    color: white !important;
} */
.btn-link{
    border: 0 !important;
}
.lang-card{
    margin: auto;
    padding: 10px;
    border-radius: 8px;
}

.lang-sec{
    margin-top: -32px;
    margin-bottom: 8px;
}
.lang-btn-input label {
    border-radius: 45px !important;
    border: 1px solid #888;
    background: white !important;
    color: #888;
    box-shadow: 0 0 black;
}
.lang-btn-input label:hover, .lang-btn-input label:active{
    border-radius: 45px !important;
    border: 1px solid #888;
    background: #eb1163 !important;
    color: #fff;
    box-shadow: 0 0 black;
}
button.confirm-btn{
    background-color: #eb1163 !important;
    color: #fff;
    border: 0;
    box-shadow: 0 0 black;
}
/* button.btn.btn-primary:hover, button.btn.btn-primary:active{
    background-color: #eb1163;
    color: #fff;
} */
/* .confirm-btn{
    background-color: #eb1163;
    color: #fff;
} */
/* .lang-btn.btn.btn-primary{
    background-color: #eb1163;
    color: #888;
} */
/* label.btn-primary:hover,label.btn-primary:active {
    border-radius: 45px !important;
    border: 1px solid #888;
    background: #eb1163 !important;
    color: #fff;
    box-shadow: 0 0 black;
} */
/* Login CSS */
.loginHeadline{
    font-size: 24px;
    font-weight: 700;
}

.loginPage{
    background-color: #fde6ef;
    padding: 10px 30px;
    /* margin-bottom: 100px; */
}
.loginScreen{
    text-align: left;
    line-height: 2.0em;

}
.loginScreen i {
    padding-right: 10px;
}
.loginScreen .fa-comment-alt{
    color: #104284;
}
.loginScreen .fa-bookmark{
    color: #ff8f20;
}
.loginScreen .fa-heart{
    color: #eb1163;
}
.login-btn{
    border-radius: 35px;
    padding: 10px 30px !important;
    background-color: #eb1163;
    border: #eb1163; 
    color: #fff;
    margin: auto;
    display: block;
}

.loginBtn{
    border-radius: 35px;
    padding: 10px 30px;
    background-color: #eb1163;
    border: #eb1163;
}
.loginBtn a{
    color: white !important;
}
.loginvectorImg{
    text-align: center;
}
.loginvectorImg img {
    width: 180px;
    padding-top: 40px;
}
.loginvectorImg i{
    padding: 0 10px;
}
.loginvectorImg .fa-comment-alt{
    color: #104284;
}
.loginContent{
    text-align: center;
    padding: 20px 50px;
}
.MyShowBtn{
    margin: 30px 0;
}
.LoginMyShowCont{
    text-align: center;
}
/* Header CSS */
.logo{
    width: 80px;
}
.navbar{
    background-color: white;
    margin: 0;
    padding: 0 0 0 10px !important;
}
/* Show CSS*/
.showDetailsHeader{
    padding-bottom: 100px;
    box-shadow: 3px 4px 5px #efeeee;
    margin-bottom: 8px;
}
.showDetails-content{
    border-top: 1px  solid #efeeee;
}
.showBtn {
    padding-top: 10px;
    /* padding-bottom: 8px; */
    margin-top: 10px;
}

.showBtn small {
    display: block;
    text-align: left;
}
.showBtn .col, i {
    text-align: center;
    font-size: 12px;
    font-weight: 900;
}
.categoryName{
    color: #016135;
    padding-right: 20px;
}
.varText{
    font-size: 14px;
    font-weight: 400;
}
.varText1{
    font-size: 12px;
    font-weight: 400;
    color: #9c9c9c;
}
.nav-tabs .nav-item{
    width: 50%;
}
.sticky-navbar{
   position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: #eb1163 !important;
} 
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color: #888888 !important;
}
.showDetails{
    padding-top: 10px;
}
.showDetails a {
    color: #eb1163;
}
.showDetails span{
    float: left;
    /* margin-left: 5px; */
}
.btn{
    margin: 0 !important;
    padding: 8px 20px !important;
    border-radius: 8px !important;
}
.btn i{padding-right: 5px; color: #ffffff !important}

.showDescription{
    margin-top: -30px;
}

.artistName{
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    padding-top: 20px;
    margin-bottom: 0;
}

span.episodeName{
    font-weight: 900;
}

.episodeDesc span{
    font-size: 14px;
    color: #888888 ;
}
.episodeDesc div{
    color: #888888;
}

.episodeDesc a{
    color: #eb1163 ;
}
.episodeDuration div span{
    font-size: 14px;
    color: #888888;
}

div.playBtn.col{
    text-align: center;
}

.tab-content{
    padding-top: 10px;
}

div.showImg.card{
    border-radius: 10px;
}
.showDetailName{
    font-size: 22px;
    /* font-weight: 600; */
    margin-bottom: 6px;
}
.input-group> .form-comment-input{
    width: 95% !important;
    height: 42px;
    border-radius: 35px !important;
}
.comment-btn{
    position: absolute;
    right: 15px;
    top: 1px;
    z-index: 5;
}
.player-form-comment-input{
    width: 100% !important;
    height: 46px;
    border-radius: 35px !important;
}
.player-comment-btn{
    position: absolute;
    right: 15px;
    width: 46px;
    top: 0px;
    z-index: 5;
}
.commentName{
    font-size: 12px;
    padding-right: 10px;
    text-transform: capitalize;
}
.commentDuration{
    font-size: 12px;
    color: #cccccc;
}
.commentDesc{
    font-size: 14px;
    font-weight: 500;
    display: block;
}
.comment-img{
    margin: 8px;
}
.commentUserPic{
    float: left;
    width: 60px;
}
.comment-description{
    margin: 8px 0;
}
/* .commentBody{
    
} */
/* Channel CSS */

.headerBackground{
    margin-top: 20px;
    background: #302b29;
    /* height: 100%; */
    width: 100%;
}
.ChannelBody{
    border-radius: 25px 25px 0 0;
    background: white;
    padding: 0 20px;
    margin-top: 0px;
}
.channelName{
    font-size: 25px;
    font-weight: 400;
    padding-top: 36px;
}
.channelPara{
    text-align: left;
}
.ChannelGrid{
    padding: 20px 0;
}
.tagName{
    font-size: 10px;
    color: #ed2872;
    font-weight: 600;
    margin-left: 20px;
}


.shareText{
    font-size: 13px;
}
.channelLogo {
    margin: auto;
}

.ShowImage{
    width: 100px;
    /* border-radius: 10px; */
}



.bottomPadding10{
    padding-bottom: 16px;
}

/* Category CSS */

.categoryBackground{
    background: rgb(243 212 243);
    height: 100%;
    width: 100%;
}

.CatBody{
    border-radius: 25px 25px 0 0;
    background: white;
    padding: 20px;
    /* box-shadow: 0px -9px 10px -5px grey;  */
}



.catImage{
    height: auto;
    width: 80px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 2px grey;  */
}

.ShowImage{
    width: 100px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 2px grey;  */
    margin-bottom: 8px;
}

.categoryGrid{
    padding: 20px 0;
}



/* .categoryLogo img{
    width: 40px;
} */


.topbar{
    padding: 10px 20px 10px 20px;
}

.ShowImage{
    width: 100px;
}
/* Full Player */

.playerPic{
    width: 150px;
    margin: auto;
    border-radius: 15px;
    /* padding-top: 35px; */
    display: block;
}
.episodeThumb{
    margin: auto;
}
.playerText{
    text-align: center;
    font-size: 21px;
    font-weight: 900;
    padding-top: 20px;

}
.socialIcon{
    width: 30px;
    padding: 3px;
    border: 1px solid #cccccc;
    border-radius: 40px;
    margin: auto;
    display: block;

}
.social-buttons{
    display: block;
    margin: auto;
    padding-bottom: 20px;
}
.playerBtn span {
    font-size: 12px;
    color: #b2b2b2;
    text-align: center;
    padding-bottom: 10px;
}
.plyrAdditionalBtns{
    font-size: 500px;
    
}
.playr-actions-nav .col{
    text-align: center;
}
.playr-actions-nav p{
    font-size: x-small;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
}
/* Player CSS*/
/* .rhap_time{display: none;} */
.episodePic{
    /* width: 100px; */
    border-radius: 15px 0 0 15px;
}
.playerSec{
    position: fixed;
    display: block;
    bottom: 0px;
    left: 0px;

}
.rhap_play-pause-button{
    width: 50px;
    height: 50px;
}
/* .playerAudio{
    height: 60px;
    background-color: #1d1d1d !important;
    color: #ffffff !important;
    border-radius: 0 15px 15px 0;
} */
.rhap_progress-bar{
    height: 2px !important;
}
.rhap_progress-filled{
    background-color: #eb1163 !important;
}
.rhap_download-progress{
    background-color: #841740 !important;
}
.rhap_progress-bar-show-download{
    background-color: #841740 !important;
}
.rhap_progress-container{
    height: 2px !important;
}
.rhap_progress-indicator{
    width: 10px !important;
    height: 10px !important;
    top: -4px !important;
    background: #eb1163 !important;
    border: 3px solid #fff;
}
/* div.rhap_time{
    color: white;
} */
/* .rhap_time, .rhap_rewind-button, .rhap_forward-button{display: none;} */

/* My Shows Css */
.myShowsTab .nav-link{padding: 12px 12px !important;}
.nav-tabs .nav-link{
    border: 1px solid transparent !important;
    /* padding: .5rem 1rem; */
}
.nav-tabs{
    border: 1px solid transparent !important;
}
.nav-tabs .nav-link.active{
    border-bottom: 3px solid #eb1163  !important;
}
.testbg{
    background: yellow;
    width: 100%;
}
.searchForm{
    width: 100%;
}
.searchSubs input{
    border-radius: 12px;
    width: 340px;
}
.recentShows{
    padding-top: 20px;
    font-size: 12px;
    font-weight: 500;
}
.recentShows a{
    color: #eb1163;
}
.recentShows span{
    color: #eb1163; float: right; padding: 0 20px;
}
.subTitle{
    font-size: 9px;
    color: #b2b2b2;
}
div.showCardSub.card{
    border-radius: 10px;
    width: 100%;
}

.breadcrumbs{ padding-top: 20px; font-size: 12px; padding: 0 20px; font-weight: 500;}

.breadcrumbs span { color: #eb1163; float: right; padding: 0 20px;}
.tab-content{width: 100%;}
.episodeList{padding: 10px 0;}
.showDetailImg{ width: 240px !important; }

/* Header Tab CSS */
.navTabBody .showImgCard{
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 8px;
    box-shadow: none;
}
.navTabBody .showImgCard .card-img {
    border-radius: 50%;
    padding: 0;
}
.activeheader{
    color:  #eb1163 !important;
}
.activeheader i{
    color:  #eb1163 !important;
}

.headerHover:hover{
    color:  #eb1163 !important;
}
.dropdownHeaderMore {
    position: absolute;
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -42;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 30%;
    margin: 0px auto;
    max-width: 100px;
     display: none;
}
.dropdownHeaderMore >a {
   text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:12%;
}
.dropdownHeaderMore:hover  {
    display: block;
}

.dropdownHeaderMore-container:focus {
  outline: none;
  display: block;
}

.dropdownHeaderMore-container:focus .dropdownHeaderMore {
  opacity: 1;
  display: block;
  /* z-index: -1; */
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}

/* PROFILR LOGOUT DROPDOWN*/
.Profile-icon:after {
  cursor: pointer;
  color: #FCC;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}
.dropdownHeaderProfile {
    position: absolute;
    left: 15px;
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -42;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 95%;
    margin: 0px auto;
    max-width: 100px;
    display: none;
}
.dropdownHeaderProfile >div {
   text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:12%
}

.dropdownHeaderProfile-container:focus {
  outline: none;
}

.dropdownHeaderProfile-container:focus .dropdownHeaderProfile {
  opacity: 1;
  display: block;
  /* z-index: -1; */
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}


/* Mobile only */
@media (min-width: 768px){
    .mobile-only{ display: none}
    .mobile-only-imp{display: none !important;}
    .page-catCha{padding: 0px 20px}
    .post-img{ 
        width: 100%;
         border-radius: 10px 10px 0 0;
        }
    .post-name{
        font-size: 28px;
        padding: 12px 6px;
        color: black;
    }
    .author-name{
        font-size: 12px;
        text-align: left;
        float: left;     
    }
    .pubdate{
        font-size: 12px;
        text-align: right;
    }
    .read-category-name{
        font-size: 11px;
        position: absolute;
        background-color: #222222;
        opacity: 0.8;
        padding: 3px 3px 0px 3px;
        margin: 6px;
        border-radius: 3px;
    }
    .read-category-name p{
        color: white;
    }
    .read-page-title{
        font-family: 'BwModelica-Bold';
        font-style: normal;
        font-size: 2em;
        line-height: 22px;
        padding: 31px 15px;
    }
    .post-mid-band{
        background-color: rgba(96, 25, 138, 0.9);
    }
    .post-mid-band p{
        color: #fff;
        opacity: 1;
        padding: 8px;
    }
    .post-card{
        border-radius: 10px !important;
        border: 1px solid #ebebeb !important;
        box-shadow: 3px 7px 5px #ebebeb !important;
    }
}
/* Desktop only */
@media (max-width: 767px){
    .desktop-only{ display: none}
    .post-img{ width: 100%;}
    .post-name{
        font-size: 16px;
    }
    .author-name{
        font-size: 12px;
        text-align: left;
    }
    .category-name{
        font-size: 12px;
    }
}

.asterisk{
  color: red;
}
  .card-footer a{
    color: #ec1165;
  }
  .card-title{
    color: #000000c7;
  }
  .card-text{
    color: #000000a3;
  }
  .card a {
    text-decoration: none !important;
  }
  .card:hover {
    box-shadow: 0px 0px 19px 8px rgba(33,33,33,.2);
  }
  
  
  #exTab2 {
    /* color : white;
    background-color: #428bca; */
    border: 1px solid #8080807a;
    padding : 5px 15px;
  }
  .tab-box{
    border-bottom: 1px solid #8080807a;
  }
  .tab-box a {
    text-decoration: none;
  }
  .card-header {
    text-align: left;
  }
  .card-header button{
    text-decoration: none !important;
  }
  .panel-title {
    font-size: 20px;
    font-weight: 700;
    color: #606060;
  }
  
    
  /* .panel-title::after {
      content: "\f107";
      color: #333;
      top: -2px;
      right: 0px;
      position: absolute;
      font-family: "FontAwesome"
  } */
  
  /* .panel-title[aria-expanded="true"]::after {
      content: "\f106";
  } */
  .card-body{
  
    text-align: left;
  
  }
  .online-card{
    padding: 10px;
  }
  
  #myImg {
    border-radius: 5px;
    cursor: pointer !important;
    transition: 0.3s;
  }
  #myModal7{
    opacity: 1 !important;
  }
  .showimage{
    max-width: 100%;
      height: auto;
      vertical-align: middle;
  }
  .modal{
    top: 50px !important;
  }
  .media-content #myImg{
    width: 100% !important;
  }
  .panel-heading{
    text-align: left !important;
  }
  .mediadivide{
    border-right: 1px solid #00000087 !important;
  }
  .textLeft
  {
    text-align: left !important;
    font-family: sans-serif !important;
    /* font-size: 16px !important; */
    /* padding: 35px !important; */
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .textLeft a
  {
    color: #ed4b64 !important;
  }
  @media only screen and (max-width: 800px) {
  /* iframe{
    width: 100% !important;
    height: 615px !important;
  } */
  }
  
  .faqtext .panel-heading{
    padding: 15px !important;
    color: #ed4b64 !important;
  
  }
  .faqtext p {
    text-align: left !important;
  }
  .faqtext{
    padding-top: 25px !important;
  }
  .faqtext p a{
    color: #ed4b64 !important;
  }
  .contactForm{
    background: #d3d3d333 !important;
  }
  nav{
    border-bottom: 1px solid lightgrey !important;
    background: #fff !important;
  }

/* Mobile only */
    @media (min-width: 768px){
        .mobile-only{ display: none}
        .mobile-only-imp{display: none !important;}
        .page-catCha{padding: 0px 20px}
        
       
  }
/* Desktop only */
    @media (max-width: 767px){
        .desktop-only{ display: none}
  }

  /*  ipad pro css Start */
 @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

    .right-menu {
 right: 50px !important;
}
 .vsearch-btn{
    right: -19px !important;
 z-index: 1
}
.slick-prev{
  display: none !important;
 }
.slick-next{
  display: none !important;
 }
  .search-result-img {
    width: 96px !important;
}
.MainContainer{
width:75% !important;
}
.recommend-hover-effect {
    margin: 10px !important;
}
.live-show-img {
    /* border-radius: 10px; */
    width: 138px !important;
}
.time-section {
   padding: 7px 5px !important;
    margin-right: 3px !important;
    margin-left: -8px !important;
}
.schedule-box {
    width: 137px !important;
}
}
/*  ipad pro css End */


/* Mobile Css */
@media (max-width: 768px){
     .slick-prev{
             display: none !important;
        }
        .slick-next{
             display: none !important;
        }
    .mobi-right-bar{float: right; position: absolute; right: 0;}
    .mobi-right-bar a {margin-right: 16px;}
    .footer-chan-icon{width: 23px;}
    .footer-cat-icon{width: 23px;}
    .footer-liv-icon{width: 23px}
    .Homesection{position: relative ; padding-bottom: 20% }
    .live-gif {width: 60px}
    .navbar-expand .navbar-nav .nav-link{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .likeCounts >i{
        font-size: 30px;
        padding-bottom :8px;
        display: none;
    }
    .footer-nav a, p{
        padding: 0
    }
    .subscribe-txt{color: #eb1163;}
    .subscribe-txt .img-top{display: none;}
    .channelName {
    font-size: 15px;
    font-weight: 400;
    padding-top: 0px;
}
/* .subscribe-txt:hover .img-top{display: inline; position: relative; top: -35px; z-index: 99;} */
    input.check-btn{
        width: 20px;
        height: 20px;
        float: right;
        border-radius: 50% !important;
        border-radius: 100%;
        vertical-align: middle;
        border: 1px solid #ddd;
        background-color: black;
    }
    button.btn-link:hover{
        color: white;
        box-shadow: none;
        background: #eb1163;
    }
    button.btn-link{
        color: white;
        box-shadow: none;
        background: #eb1163;
        margin: auto!important;
        width: 140px;
        border-radius: 25px !important;
    }
    .sectionName{font-size: 14px; font-family: "BwModelica-Bold"; color: #1d1d1f; padding-top: 30px; letter-spacing: 0.5px; margin-bottom: 8px; padding-left: 15px;}

    #login-modal.modal-dialog{width: 800px !important;}
    .modal-dialog .modal-content{border-radius: 18px !important;}
    .modal-title{    
        font-size: 21px;
        font-weight: 600;
        margin: 8px 2px;
        font-family: "BwModelica-Bold";
    }
    .login-txt{
        font-family: "BwModelica-Light";
        font-size: 14px;
    }
    .login-policy-txt{
        margin-top: auto;
        font-family: "BwModelica-Light";
        font-size: 12px;
    }
    .login-policy-txt a{
        color: #eb1163;
    }
    .modal-ln-title{    
        font-size: 21px;
        font-weight: 600;
        margin: 8px 2px;
        font-family: "BwModelica-Bold";
    }
    /* .modal-content {
        height: 300px;
      } */
    /* .check-btn{margin: 12px 2px;} */
    .selection-box label{padding: 10px 0;}
    .modal-subtitle{font-family: "BwModelica-Light";}
    .modal-dialog .modal-content .modal-header{border-bottom: none;}
    .modal-dialog .modal-content .modal-footer{border-top: none;}
    .MainContainer{
        width:100%; 
        margin-top: 55px;
        }
    .footer-nav{
        font-size: 12px;
        background: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding:10px;
        box-shadow: 2px 3px 7px #ccc;
        border: 1px solid #ebebeb;
        border-radius: 16px 16px 0 0;
        z-index: 2;
    }
    .active svg, .active p
    {color: #eb1163 !important;}
    
.footer {
    box-shadow: 0px 0px 15px 2px grey;
    border-radius: 12px 12px 0px 0px;
    
    background-color: white;
    position: fixed;
    display: block;
    bottom: 0;
    width: 100%;
    right: 0;
  }
  .main-menu{ display: flex; align-content: center; align-items: center;}
    .main-menu a{color: #eb1163; padding: 0 5px;}
    .live-btn{    background-color: #eb1163;  color: white !important;  padding: 2px 3px; border-radius: 5px;}
    .live-item a{
        animation: blink-animation 1s steps(5, start) infinite;
        -webkit-animation: blink-animation 1s steps(5, start) infinite;
      }
      @keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
      @-webkit-keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
    .dfooter{display: none;}
    /* .card{box-shadow: none !important;} */
    .card-body{ margin-top: -50px; box-shadow: 3px 5px 7px #cccc; border: 1px solid #ccc; border-radius: 8px;}
    .showbtn-more button{    
        background: #eb1163 !important;
        color: #fff !important;
        float: left;
        box-shadow: 0 0 #000;
    }

    .episode-play-btn{position: absolute; width: 40px; opacity: 0.6; margin: 29px;}
    .episode-img{width: 100%; border-radius: 10px; padding: 0 3px;}
    .bannerv2{width: 100%; margin-top:0 auto; padding: 5px; border-radius: 16px;}
    .bannerv2Img{max-width: 450px;
    min-width: 342px;
    margin: 0px 7px;
    border-radius: 6px;}
    .react-multi-carousel-list  {margin-bottom: 25px;}
    .react-multi-carousel-track li {width:300px;}
    .player-like-btn{ width: 70px;}
    .closeButton1{width: 20px; position: absolute; top: -25px; right: 5px}
    .voicesearch-btn{
        position: absolute;
        right: 30px;
        top: 1px;
    }
    .page-title{
    text-align: left;
    font-weight: 900;
    font-size: 18px;
    padding: 15px;
    border-bottom: 1px solid grey;
    }
    
    .social-player-links{
        display: block;
        margin: auto;
        text-align: center;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .social-player-links img{
        width: 30px;
    }
    .channelLogo img{
        width: 80px;
        padding-bottom: 20px;
    }
    .categorySec{
       width: 100%;
      margin: auto; 
      border-radius: 5px;
    }
    .categorySecRadius{
        border-radius: 5px;
    }
    .categorySecTitle{
        font-size: 0.6em; font-weight: 900; color: white; line-height: 1em; padding: 18px 0;
    }
    .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show>.btn-primary.dropdown-toggle{
        background-color: #eb1163 !important; color: #fff !important;
    }
    .btn-group-toggle label.active {background-color: #eb1163; color: #fff;}
    .btn-group-toggle label:hover{background-color: #eb1163; color: #fff;}
    /* .btn-group-toggle label{background-color: #fff !important; color: #eb1163 !important; border: 1px solid #eb1163 !important; border-radius: 40px !important; box-shadow: none;} */
    .share-sub-section{padding-top: 20px; text-align: center;}
    /* Home Section Css */
    .catTitle{ font-size: 12px; max-width: 100px;}
    .show-name{ font-size: 14px; text-align: left; color: gray; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px;}
    .live-blinker{width: 20px; float: left;}
    /* Player Section CSS */
    .player-like-btn{ width: 20px; position: absolute; top: 15px; right: 10px;}
    .ReactSwipeableBottomSheet--open{
       /* height: 300px !important;  */
        max-height: 300px !important;
    }
    .playBtn i{display:none;  } 
.showTitle:hover .playBtn {display: block; position: absolute; right: 10px;}
.dchannelCard {
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dchannelImg {
    width: 70% !important;
    box-shadow: none !important;
}
.itemsContainer {
    margin: 10px 5px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 6px 8px 11px #E6EAED;
    height: 70px;
    overflow: hidden;
}
.itemsContainer1 {
    margin: 10px 5px;
    /* background-color: white; */
    border-radius: 10px;
    /* box-shadow: 6px 8px 11px #E6EAED; */
}
.ditemsContainer1 {
    /* margin: 10px 5px; */
    /* background-color: white; */
    /* border-radius: 10px; */
    /* box-shadow: 6px 8px 11px #E6EAED; */
    cursor: pointer;
}


.itemsContainer2 {
    margin: 10px 5px;
    /* background-color: white; */
    border-radius: 10px;
    /* box-shadow: 6px 8px 11px #E6EAED; */
}
/* .itemsContainer1:hover{box-shadow: 7px 5px 3px #ccc;}  */
.itemsContainer:hover .play i{display:block; background-color: #eb1163; color: white; padding: 8px; border-radius: 50%;}
h4.channelTitle{
    padding-top: 4px;
    padding-bottom: 4px;
    color: black;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    overflow: hidden;
    max-width: 200px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}
h4.category-title{
    padding-top: 4px;
    padding-bottom: 4px;
    color: black;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    overflow: hidden;
    max-width: 200px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}
.itemsContainer1:hover{opacity: 0.8; background-color: #fff;}  
.ditemsContainer1:hover{opacity: 0.8; background-color: #fff;}  
.itemsContainer1:hover .play1 i{display:block; background-color: rgba(0, 0, 0, 0.888); color: white; padding: 20px; border-radius: 50%;margin-left: 45px;margin-right: auto;}
.ditemsContainer1:hover .play1 i{display:block; background-color: rgba(0, 0, 0, 0.888); color: white; padding: 20px; border-radius: 50%;margin-left: 45px;margin-right: auto;}
.play i{
  position : absolute;
    display:none;
    margin:0 auto; 
    top: 30%; 
    /* left:0px; */
    right:35px;
    z-index:100;
    font-size: 14px;
    color: white;
} 
.play1 i{
    position : absolute;
      display:none;
      top: 28%;
      z-index:100;
      font-size: 21px;
      color: white;
  } 
    .ChannelBody{
    background: #e6eaed;
    padding-bottom: 100px;
    }
    .CatBody{
        background: #e6eaed;
        padding-bottom: 100px;
    }
    .showImgCard{
        border-radius: 10px;
        box-shadow: none;
        height: 100%;
        box-shadow: 3px 7px 5px #e6eaed;
    }
    .back-btn{position: absolute; top: 10px; left: 10px;}
    .catName{
        text-align: center;
        font-size: 21px;
        font-weight: 600;
        color: black;
        margin-top: -50px;
        font-family: 'BwModelica-Bold';
    }
    .categoryLogo {
        /* text-align: center; */
        margin: auto;
        width: 100%;
        padding-top: 50px;
        height: 60px;
    }
    .categoryLogoBac{
        margin-top: 20px;
        width: 100%;
    }
    .notify-board{background-color: yellow; margin: 20px; border: 1px solid #ebebeb; box-shadow: 3px 7px 5px #ccc; border-radius: 10px;}
    .panel-title{font-size: 14px;}
    .textLeft{font-size: 12px; padding-bottom: 25px;}
    .notification-content{ padding: 16px; color: #888888; text-align: left; font-size: 13px;}
     .episode-details-inner{ width: 100%; position: absolute; bottom: 4px;}
      .PlayerEpisodePic{width: 60px; float: left; margin-right: 8px;}
       .player-episode-title{font-size: 12px; margin: 15px 0px 6px 0px;}
    .stitle{color: rgba(0,0,0,0.5); font-size: 10px; letter-spacing: .4px; padding-right: 50px;}
    .rhap_main-controls {
    z-index: 1;
}
    /* Contact Page */
    .contact-section{margin: 16px; }
    
/* **********************
        Static Pages Css
**************************/
.cat_page-content{
    padding: 0px 0px 80px 0px;
}
.page-content{
    padding: 0px 40px 80px 40px;
}
.page-header{
    font-family: "BwModelica-Bold";
    text-align: left !important; 
    font-size: 40px !important;
    margin: 20px 0;
}
.sub-header{
    font-family: 'BwModelica-Bold';
}
.page-link{
    font-family: 'BwModelica-Bold';
    font-size: 14px;
    color: #eb1163;
}
.header-border{
    /* border-bottom: 8px double #eb1163 ;
    width:100px; */
}
.page-para{
    font-family: 'BwModelica';
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #606060;
}
.page-para a{
    color: #eb1163;
}
/* .subscribe-txt {
    width: 35px;
    height: 35px;
    margin: 0 0 0 10px;
    position: relative;
    display: inline-block;
} */
.isNotSubscribed {
    width: 40px;
}
/*******************************
        Live Show Page
********************************/

.live-show-grid{padding: 14px; border-radius: 10px;margin: 5px 0px;}
.live-show-img{border-radius: 10px; width: 100%;}
.live-show-title{font-family: 'BwModelica-Bold'; font-size: 15px;}
.live-show-description{padding-top: 8px; color: #888; text-align: left;}
.table-borderless{text-align: center; }
tr.live-timer td{background-color: #e6e9ee; border-radius: 15px; border: 6px solid white; width: 40px;text-align: center;font-size: 9px;}
.live-timer-days td{font-size: 9px;
    text-align: center;}
/* .live-timer-hours{margin-right: 50px !important;font-size: 12px;} */
.schedule-box{ background-color: #452d79; padding: 4px;  border-radius: 3px; color: white; margin-top: 10px; font-size: 12px;}
.time-section{
    background-color: #eb1163;
   padding: 7px 12px !important;
    border-radius: 4px;
    margin-right: 8px;
    margin-left: -5px;

}
.reminder-btn{background-color: #888888; border-radius: 3px; text-align: center; color: white; text-transform: uppercase; border: none; font-weight: 600; padding: 4px 20px;font-size: 12px;}
.live-join-btn{background-color: #eb1163; border-radius: 3px; text-align: center; color: white; text-transform: uppercase; border: none; font-weight: 600; padding: 4px 20px;font-size: 12px;}
.date-section{}
/* About Page */

/*  Category list tab */

.category-grid1{padding: 2px;}
.category-grid2{padding: 10px; text-align: center;}
.category-grid1 img{margin: auto;}
.categoryBox{background-color: white; border-radius: 22px; box-shadow: 3px 7px 5px #E6EAED; padding: 7px 0px;}
.categoryBox img {width: 40px;
    margin: auto;
    padding: 7px 0px;}
.ChannelBox{background-color: white; border-radius: 22px; box-shadow: 3px 7px 5px #E6EAED; padding: 20px 0px;}
.categoryBox:hover{box-shadow: 0px 5px 10px 5px #ebeff3;}
.ChannelBox:hover{box-shadow: 0px 5px 10px 5px #ebeff3;}
.category-icon{width: 80px; padding: 15px 0;}

/* Channel */
.channel-image{
    border-radius: 50%;
    width: 80px;
    margin: auto;
    padding: 15px 0;
}
}

/* Desktop Css */
@media only screen and (min-width: 768px) {

/*******  Index of CSS ************
0. Element CSS
1. Sidebar CSS
2. Header CSS
3. HomePage CSS
4. Footer CSS
5. Show Page CSS
***********************************/


/**************************
        0. Element CSS
****************************/
/* body{
    overflow-x: hidden !important;
} */
thead{border-bottom: 0px solid #dee2e6;}
.table tbody+tbody{border-top: 0px solid #dee2e6;}
.scroll-up{
    float: right;
    position: fixed;
    right: 20px;
    bottom: 10px;
    background-color: #eb1163;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 2px 3px 5px #ccc;
}
.headerBackground{
    background: #e6eaed;
}
.ChannelBody{
    background: #f5f5f7;
}
/* Login Popup */
.channelRow::-webkit-scrollbar { width: 1em;}
.react-multi-carousel-list {
    width: 100% !important;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0px;
    padding: 0;
    width: 100% !important;
}
.modal-header .close{
    padding: 4px 8px !important;
}
.close{font-size: 2rem !important;}
input.check-btn{
    width: 20px;
    height: 16px;
    float: right;
    border-radius: 50% !important;
    border-radius: 100%;
    vertical-align: middle;
    border: 1px solid #ddd;
    background-color: black;
}
button.btn-link:hover{
    color: white;
    box-shadow: none;
    background: #eb1163;
}
button.btn-link{
    color: white;
    box-shadow: none;
    background: #eb1163;
    margin: auto!important;
    width: 140px;
    border-radius: 25px !important;
}
#login-modal.modal-dialog{width: 800px !important;}
.modal-dialog .modal-content{border-radius: 10px !important; padding: 20px 20px 30px 20px;}
.modal-title{    
    font-size: 21px;
    font-weight: 600;
    margin: 8px 2px;
    font-family: "BwModelica-Bold";
}
.modal-backdrop.show{
    opacity: 0.7;
}
.login-txt{
    font-family: "BwModelica-Light";
    font-size: 14px;
}
.login-policy-txt{
    margin-top: auto;
    font-family: "BwModelica-Light";
    font-size: 12px;
}

.login-popup{
    width: 420px ;
    left: 42%;
    border-radius: 18px
}
.popup-lang-btn{font-weight: 600;}
.login-popup .modal-header{padding: 0 0 0 1rem;}
.login-popup .modal-body{padding: 0 1rem;}
.login-popup .modal-footer{padding: 0 1rem;}
.modal-ln-title{    
    font-size: 21px;
    font-weight: 600;
    margin: 8px 2px;
    font-family: "BwModelica-Bold";
}
.lang-modal-name{
    font-size: 16px;
    margin: 8px 2px;
    font-family: "BwModelica-Bold";
}
.lang-modal-subname{
    font-size: 12px;
    font-family: 'BwModelica';
}
.lang-modal-name input{
    float: right;
}
/* .modal-content {
    height: 300px;
  } */
.check-btn{margin: 12px 2px;}
.modal-subtitle{font-family: "BwModelica-Light";}
.modal-dialog .modal-content .modal-header{border-bottom: none;}
.modal-dialog .modal-content .modal-footer{border-top: none;}

body {
    font-family: "BwModelica";
}
.background {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
            backdrop-filter: saturate(180%) blur(10px);
    position: fixed;
    width: 100%;
    height: 72px;
    z-index: 3;
}
.player-background {
    z-index: 3;
    /* background: rgba(255, 255, 255, 0.8); */
    /* backdrop-filter: saturate(180%) blur(10px); */
    position: fixed;
    width: 100%;
}
.MainContainer{
width:80%;
margin-left: auto;
margin-right: auto;
min-height: 100vh;
max-height: auto;
}
.page-title{
    font-family: 'BwModelica-Bold';
    font-style: normal;
    font-size: 2em;
    line-height: 22px;
    padding-bottom: 15px;
}
   /* 1. Sidebar CSS */
   .tab-container nav{border-bottom: none !important;}
   .pro-sidebar .pro-menu .pro-menu-item:hover, .pro-sidebar .pro-menu .pro-menu-item:active{background-color: #f5f5f7; border-radius: 8px; }
   .pro-sidebar .pro-menu .pro-menu-item .pro-icon-wrapper .pro-icon img:hover, .pro-sidebar .pro-menu .pro-menu-item .pro-icon-wrapper .pro-icon img:active{background-color: #f5f5f7; }
   .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper, .pro-sidebar .pro-menu{background-color: #fff !important;}
   .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon img:hover{background-color: white !important;}
   .pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #888888 !important;
    font-family: 'BwModelica-Bold';
    font-size: 14px;
}
   /*
 *  Sidebar ScrollBar STYLE 3
 */
   .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
        background-color: #fff; margin-top: 105px 0; 
        position: fixed; top: 55; z-index: 99; height: 600px;max-width: 205px;
        min-width: 10px;
        height: 100vh;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px #E6EAED;
    background-color: white;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar
{
    width: 3px;
    background-color: white;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar-thumb
 {
     background-color: #CAD0D5;
     height: 5px;
     max-height: 10px;
 }
/*
 *  Sidebar ScrollBar STYLE 3
 */
 /* .sidebar-scrollbar{
    margin: 12px 8px 0 8px;
	float: left;
	height: 600px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}
 #style-3::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px grey;
     background-color: #F5F5F5;
 }
 
 #style-3::-webkit-scrollbar
 {
     width: 2px;
     background-color: #F5F5F5;
 }
 
 #style-3::-webkit-scrollbar-thumb
 {
     background-color: grey;
 } */
 .Homesection{position: relative ;  }

 .pro-sidebar.collapsed .nav-tabs .nav-link{ border-bottom: 3px solid #f9b8d0 !important; width: 100%;}
.pro-sidebar.collapsed .pro-menu .pro-menu-item > .pro-inner-item{padding: 8px 5px 8px 20px ;}
   .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header{padding-top: 70px; margin-left: auto; border-bottom: none;}
   /* .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{height: none;} */
   .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item:hover{background-color: transparent; margin: 0;}
   .pro-sidebar.lg.collapsed nav a{background-color: transparent; display: none;}
   .pro-sidebar.lg.collapsed nav a.active{display: block; box-shadow: none !important; color: black !important; margin: 0 0 0 2px; padding: 12px 2px; font-size: 12px; text-align: center;}
   .pro-sidebar .pro-menu{padding: 0 0 0 0;}
   .pro-sidebar {
    width: 200px;
    min-width:  200px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{padding: 12px 35px 12px 20px;}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    white-space: normal;
}
 

    .right-sidebar{ margin-right: -21px;}
   .sidebar{background-color: #E6EAED !important; border-right: 1px solid #ccc;}
   .sidebar-container{padding: 1px;}
   .sidebar-btn.tab-link-active{background-color: white; border-radius: 35px; border: white; color: #eb1163; box-shadow: 3px 5px 7px #ccc;}
   .sidebar-btn{ border: none; color: #888888; background-color: #E6EAED; margin: 2px 8px;}
   .sidebar-sec{padding: 10px;}
   .nav-tabs{background-color: transparent !important;}
   .nav-tabs .nav-link{padding: 12px 12px; text-align: center; margin: auto;} 
   .nav-tabs .nav-link.active{ border: 1px solid #E6EAED; border-bottom: 3px solid #f9b8d0 !important; font-weight: bold;}
   .nav-tabs .nav-link{display: block; box-shadow: none !important; color: black !important; margin: 0 0 0 2px; padding: 12px; font-size: 12px; text-align: center; font-family: 'BwModelica-Bold';}

   /* Channel Tab */
    .channel-sec ul li{list-style-type: none; padding: 2px 0; line-height: 18px;}
    
    .category-sec ul li{list-style-type: none; line-height: 18px;}
   .chan-tab-img{width: 40px; border-radius: 35px; margin-right: 8px;}
   .channel-sec ul{-webkit-padding-start: 0px;padding-inline-start: 0px;}
   .channel-tab a{color: #888888;}
   .channel-tab li:hover{color: #434343 !important;}
   .channel-tab img:hover{border: 4px solid white;}
   /* Category Tab */
   .sidebar-icon{width: 40px; border-radius: 35px;}
    .category-title{ color: #888888;}
    /* .category-title:hover{ color: black; border: 1px solid white; background-color: white; border-radius: 8px; box-shadow: 3px 5px 7px #ccc; width: 100%;} */
    .category-title i{ color: #888888; margin: 10px;}
    .category-sec ul{-webkit-padding-start: 0px;padding-inline-start: 0px;}
    
    /* ScrollBar CSS */
    .category-sec::-webkit-scrollbar {
        width: 0.2em;
        background-color: #E6EAED;
      }
       
    .category-sec::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #E6EAED;
      }
       
    .category-sec::-webkit-scrollbar-thumb {
        background-color: #CAD0D4;
        outline: 1px solid #E6EAED;
      }
      

    .channel-sec::-webkit-scrollbar {
        width: 0.2em;
        background-color: #E6EAED;
    }
    
    .channel-sec::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #F5F5F7;
    }
    
    .channel-sec::-webkit-scrollbar-thumb {
        background-color: #CAD0D4;
        outline: 1px solid #E6EAED;
    }

   /* 1. Sidebar CSS /End  */
   .dropdown-toggle::after{display: none;}
    body{
        background-color: #F5F5F7;
    }
    .navbar{
        background-color: #fff;

    }



    .epiImg{
        margin-top: 0px;
    }
    .epi-play-btn{
        width: 50px;
        /* height: 5%; */
        /* background-color: #000000; */
        position: absolute;
        opacity: 0.6;
        margin-top: 5%;
        margin-left: 4%;
        margin-right: auto;
        /* display: inline-block; */
        /* align-items: center; */
        /* top: 12px;
        /* display: block;  */
        /* right: 4%; */
    }


.react-multiple-carousel__arrow{
    background: #eb1163 !important;
    opacity: 0.6 !important;
    border: none;
} 
/* .react-multiple-carousel__arrow{
    background: transparent !important;
}*/
.react-multiple-carousel__arrow:hover{ 
    opacity: 1 !important;
}/*
.react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right{
    top: calc(30% - 10px);
}

/* sideBar Nav */
.sticky-right-sidebar {
    float: left;
}
.sidebar-menu-item i span{
    font-family: 'Mukta', sans-serif;
    font-weight: 100;
    font-size: 14px;
}
.activeheader i span{
    font-weight: bolder;
    text-transform: uppercase;
}
/* .activeheader span.headerHover{border-bottom: 3px solid #eb1163; padding-bottom: 8px;} */
/* .header-brand{ padding: 10px 16px;} */
/* nav.sticky-navbar.navbar.navbar-expand.navbar-white.bg-white{margin-top: -24px;} */
.bm-menu-wrap {top: 0; left: 0px;}
.bm-overlay{top: 0;}
.navbar{padding: 0;}
.bm-burger-button button{ width:50px !important; height: 50px !important;}
.react-multiple-carousel__arrow{z-index: 1 !important;}
.sidebar-menu-list {margin: 25px 0;}
.react-burger-menu-btn{width: 60px; height: 60px;}
/* .menu-icons{position: absolute; top: 10px; left: 10px;} */
.sidebar-menu-item{ font-size: 18px; font-weight: 500; color: #617d89; margin: 15px 0;}
.sidebar-menu-item:hover, .sidebar-menu-item:active{color: #eb1163;}
.sidebar-menu-item i {font-size: 21px; padding-right: 30px; color: #617d89;}
.sidebar-menu-item i:hover, .sidebar-menu-item i:active {color: #eb1163;}
/* .sidebar-menu-item a:active, .sidebar-menu-item a:hover {color: #eb1163;} */
.hamberg { width: 50px;  position: relative; top: 3px; left: 12px; z-index: 9999;}
.showBtn .col, i{font-size: 18px;}
.bm-item a{ display: block; text-align: left; padding: 8px 25px;} 
.category-grid1{padding: 2px;}
.category-grid2{padding: 10px; text-align: center;}
.category-grid1 img{margin: auto;}
.sec-title{font-size: 21px; font-weight: 500; color:black;}
.sec-subtitle{font-size: 14px; font-weight: 500; color: #617d89;}
.cat-sec-name{font-size: 14px; font-weight: 900; color: white; line-height: 1em; padding: 20px 0 20px 10px; text-align: left; background-color: #00000042}
.channel-sec-name{font-size: 14px; font-weight: 900; color: black; line-height: 1em; padding: 20px 0;}
.cat-sec-name1{font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;}
.categoryBox{background-color: white; border-radius: 22px; box-shadow: 3px 7px 5px #E6EAED; padding: 45px 0px;}
.categoryBox img{width: 80px;
    margin: auto;
    padding: 15px 0px;}
.ChannelBox{background-color: white; border-radius: 22px; box-shadow: 3px 7px 5px #E6EAED; padding: 20px 0px;}
.categoryBox:hover{box-shadow: 0px 5px 10px 5px #ebeff3;}
.ChannelBox:hover{box-shadow: 0px 5px 10px 5px #ebeff3;}
.category-icon{width: 80px; padding: 15px 0;}

.hamberg{  z-index: 99999;}
.sidebar-logo{padding-top: 30px;}
/* .header-nav{padding: 24px 0;} */
.menu-icons{margin-top: -16px; margin-right: 15px;}
.sidebar-icons{width: 28px;}
.sidebar{padding-top: 75px; position: fixed;overflow-y: scroll; height: 100vh;}
.sidebar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #E6EAED;
}
.sidebar::-webkit-scrollbar-track
{
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #E6EAED;
}

.sidebar::-webkit-scrollbar
{
  width: 6px;
  background-color: #E6EAED;
}

.sidebar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  /* -webkit-box-sizing: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #E6EAED;
}
/* .category-sec, .channel-sec{overflow: scroll; height: 800px; padding-bottom: 175px;} */
.sub-head{ padding: 5px 5px; margin-top: 58px;}
.search-btn{width: 40px;    margin-left: 8px; cursor: pointer;}
.search-box{border-radius: 35px; width: 110% !important; border: 1px solid lightgray; padding: 10px 55px; outline: none;}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc; /* Firefox */
  }
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc; /* Firefox */
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc; /* Firefox */
  }
.download-btn{padding: 3px 6px;
    border-radius: 35px;
    border: 1px solid lightgray;
    color: #666666;
    background-color: white;
    width: 245px;
    height: 46px;
    text-align: center;
    float: right;
    cursor: auto !important;
}



.download-btn img{margin: 0 6px;  cursor: pointer; color: black;}
.download-btn img :hover{background-color: tomato;}
.sticky-right-sidebar.fixed{position: fixed; top: 35px;}
 /* Category */
.category-card{
    padding: 30px;
    border-radius: 50%;
}
.category-name{
    font-size: 18px;
    padding: 10px 0;
    font-weight: bold;
}
.category-grid{
    margin: 10px;
}

/* Channel */
.channel-card{
    /* padding: 30px; */
    border-radius: 50%;
}
.channel-image{
    border-radius: 50%;
    width: 80px;
    margin: auto;
    padding: 15px 0;
}
.channel-grid{
    margin: 8px;
}
.channel-name{
    font-size: 12px;
}
.epi-grid{
    background-color: white;
    margin: 10px 4px;
    width: 98%;
    border-radius: 8px;
}
.showTitle{padding-top: 20px;}
.episode-section {   
    margin: 4px;
    vertical-align: middle;
    height: 446px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}
.feature-episode-section{ margin: 4px;
    vertical-align: middle;
    /* height: 446px; */
    /* overflow-x: hidden;
    overflow-y: hidden; */
    width: 100%;}
.episode-img{padding: 0; }
    .kc_single_image{width: 140px}
    button:focus{outline: none;}
    .channelShows-grid .channelShows .dshowimg { width: 215px; }
    .isSubscribed img{float: left; margin-right: 5px;}
    .btn-primary{background-color: #fff !important;}
    button.btn.btn-outline-secondary{border: 1px solid #eb1163 !important; color: #eb1163 !important; background-color:#fff !important; border-radius: 35px !important; box-shadow: none !important;}
    .btn-outline-secondary:active{background-color: #eb1163 !important; color: #fff !important;}
    .listen-btn button {    
        background: #eb1163 !important;
        color: #fff !important;
        float: left;
        box-shadow: 0 0 #000;
    }
    button.confirm-btn{
        background-color: #eb1163 !important;
        color: #fff;
        border: 0;
        box-shadow: 0 0 black;
    }
    .dsearch-btn{
        position: absolute;
        right: 70px;
        top: 0px;
        width: 50px;}
    .btn-group-toggle label.active {background-color: #eb1163; color: #fff;}
    /* .btn-group-toggle label:hover{background-color: #eb1163; color: #fff;} */
    .btn-group-toggle label{background-color: #fff !important; }
    .btn-group-toggle input {padding-left: 10px;}
    .bannerv2{width: 100%; padding: 5px; border-radius: 10px;}
    .bannerv2 img{cursor: pointer;}
    .bannerv2Img{max-width: 450px;
    min-width: 342px;
    margin: 0px 7px;
    border-radius: 6px;}
    .rhap_container{width: 100%; border-right: 1px solid #f0eeee; padding: 1px 1px; 
        background: rgb(255 255 255 / 84%);
        -webkit-backdrop-filter: saturate(180%) blur(10px);
                backdrop-filter: saturate(180%) blur(10px);}
    .playlist-sec{border-left: 1px solid #f0eeee;}
    span.episodeName{font-weight: 600; font-size: 16px; color: black; vertical-align: top;}
    .showTabIcon{width: 30px; padding-right: 10px;}
    .footer-nav a, i{color: #eb1163;}

    /* .episodeDesc{margin-top: -25px;} */
    .episodeDuration{padding: 2px 0; vertical-align: top;}
    .episodeDesc span, .episodeDuration{font-size: 12px; color: #888;}
    .sec-title{font-family: 'Bw Modelica', sans-serif; font-weight: 700; font-size: 18px; line-height: 21.6px; font-style: normal;}
   
    .rhap_progress-section{ width: 55%; margin: auto; z-index: 999; padding: 0 0 8px 0;}
    .rhap_play-pause-button, .rhap_main-controls-button{z-index: 999;}
    .rhap_button-clear{overflow: inherit;}
    .rhap_rewind-button img,  .rhap_forward-button img, .rhap_skip-button svg{width: 18px; color: #868686; opacity: 0.8; margin-top: 3px;}
    .rhap_skip-button svg{width: 40px; color: #515151; }
    .carousel-nav {
        float: right;
        margin-top: -28px;
    }
    .episode-grid{padding: 5px; margin: 2px;}
    .epi-image{width: 70px; float: left; border-radius: 8px; margin-right: 12px;}
    .carousel-nav span{
        font-size: 24px;
        color: #888888;
        margin-right: 12px;
    }
    .left-arrow{
        width: 17px;
        position: fixed;
        left: 187px;
        top: 50%;
        z-index: 1;
        cursor: pointer;
    }
    .right-arrow{
        width: 17px;
        margin-right: -4px;
        position: fixed;
        top: 50%;
        z-index: 1;
        cursor: pointer;
    }
    .shareBtn {
        margin-left: 10px;
        width: 16px;
        margin-top: -8px;
    }
   


    span.episodeName,.infoBtn,.shareText, .shareBtn,.showImgCard, .isNotSubscribed span, .img-fluid, .like-btn, .likeCounts, .playBtn, .react-multi-carousel-list, div.showCard.card, .episodeImg, .dshowImg, .categoryCard, .dchannelImg, .category-grid2, i, .showTitle, .channelShows, button, div.categoryCard.card, div.channelCard.card, .channelTitle{cursor: pointer;}
    .desktop-profile{width: 40%; margin: auto; padding-bottom: 400px !important;}
    .user-profile-img{
        width: 200px;
        
    }
    .profile-sec{
        margin-top: 50px;
        margin-left: 20px;
        line-height: 32px;
    }
    .username-title{
        font-family: 'BwModelica-Bold';
        font-size: 32px;
    }
    .user-mail{
        text-align: left;
        font-size: 14px;
    }
    .user-plan{
        font-size: 14px;
        text-align: left;
    }
    .user-plan span{
        background-color: #29d067;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-weight: 600;
    }
    .userDetails{padding: 20px 15px;}
    .update-profile-form{
        margin: auto;
    }
    .field-name{
        color: #888888;
        font-size: 12px;
        padding: 10px 0;
    }
    .field-name input{
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        padding: 8px 8px;
    }
    .field-name .txt-input{
        width: 500px;
    }
    .field-name .radio-input{
        margin-top: 10px;
    }
    .field-name span{
        color: black;
    }
    .save-btn {
        background-color: #eb1163;
        padding: 4px 34px;
        border-radius: 35px;
        color: white;
        border: 1px solid #eb1163;
        margin-top: 10px;
        text-transform: uppercase;
        font-size: 12px;
    }
    .col-half-offset{
        margin-left:4.166666667%
    }

    .social-player-links{
        display: flex;
        list-style-type: none;
        margin: auto;
        text-align: center;
    }
    .social-player-links img{
        width: 30px;
    }
    .likeCounts >i{
        font-size: 35px;
        padding:2px;
        display: none;
    }
    .channelName{padding: 25px 0}
    .channel-cont{padding: 25px 0}
    .channel-sub-section{padding: 5px 0;
        display: flex;
        align-items: center;}
    .showDetails span{text-align: left; float: left;}
    .episode-details-inner{ width: 100%; position: absolute; bottom: 4px;}
    .channelLogo img{width: 180px; margin-bottom: 20px; border-radius: 50%;}
    .rhap_stacked .rhap_controls-section{ margin-top: -8px;}
    .PlayerEpisodePic{width: 60px; float: left; margin-right: 8px;}
    /* .player-episode-detail{ text-align: left;} */
    .player-episode-title{font-size: 12px; margin: 15px 0px 6px 0px;}
    .stitle{color: rgba(0,0,0,0.5); font-size: 10px; letter-spacing: .4px; padding-right: 50px;}
    .search-input-box{width: 60%;}
    .right-menu a, .right-menu a:hover{margin: 0 8px; color: #888888; text-align: right;}
    .right-menu img{width: 25px;}
    .right-menu{display: flex;justify-content: space-between; position: relative; align-items: center;right: 12px;}
    .dropdown-menu {
    left: -90px;
}
    .user-btn{width: 24px;}
    .voicesearch-btn{
        position: absolute;
        right: 65px; 
        top: 1px;
    }
    .NotifyMessage h4{
        color: #000000;
        font-size: 16px;
        font-weight: 900;
        padding: 20px;
        border-bottom: 2px solid #f6f6f6;
        cursor: pointer;
    }
    .container.userLogin{
        padding: 4vh 0; 
        /* height: 100vh; */
    }
    .desktop-input{float: left; border-radius: 20px; width: 300px; border: 1px solid #E6EAED; background: #f7f7f7;}
    .desktopsearch-btn{position: absolute; width:40px; margin-left:-30px;}
    .playerSec{
        width: 25%;
        position: fixed;
        bottom: 8vh;
        left: 36vw;
        box-shadow: 5px 4px 8px #E6EAED;
        border-radius: 25px;
        z-index: 9999;
    }
    .card{box-shadow: none !important;}
    .loginBtn{color: #fff;}
    .loginHeadline{margin-right: 15px;}
    .closeButton1{width: 20px; position: absolute; top: -25px; right: 5px}
    .lang-card{box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;}
    .modal-open .modal{overflow-y: hidden;}
    .epi-description{padding: 4px 21px; margin-top: 0.5em; background-color: #fff;}
    .vsearch-btn{
    position: absolute;
    top: 12px;
    right: -42px;
    max-width: 20px;
    width: 35px;
    vertical-align: middle;
    cursor: pointer;
    z-index: 1
}
.dsearch-btn{
    max-width: 20px;
    width: 39px;
    vertical-align: middle;
    position: absolute;
    top: 12px;
    left: 15px;
}
    .card-header{border-radius: 10px !important; border-bottom: 1px solid #E6EAED !important; margin-bottom: 5px; padding: 0 5px !important;}
    .playlist-img{border-radius: 8px; padding: 5px 1px; width: 80px;}
    .scrollbar{
        margin-left: 30px;
        height: 85vh;
        width: 34vw;
        background: #fff;
        overflow-y: scroll;
        margin-bottom: 25px;
        overflow-x: hidden;
        margin-bottom: 20px;
    } 
    .scrollbar1{
        margin-left: 30px;
        height: 85vh;
        width: 34vw;
        background: #fff;
        overflow-y: scroll;
        margin-bottom: 25px;
        overflow-x: hidden;
        margin-bottom: 20px;
    }
    .force-overflow
    {
        min-height: 450px;
    }
    
    /*
    *  STYLE 3
    */

    #style-3::-webkit-scrollbar-track
    {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
        background-color: #F5F5F5;
    }

    #style-3::-webkit-scrollbar
    {
        width: 6px;
        background-color: #F5F5F5;
    }

    #style-3::-webkit-scrollbar-thumb
    {
        background-color: #eb1163;
    }



    .miniAudioPlayer{
        width: 40%;
        display: flex;
        position: fixed;
        bottom: 20px;
    }
   .content{
       padding: 30px 0;
   }
   
    .showDetailsHeader{
        padding-bottom: 0px;
        box-shadow: 0 0 #efeeee;
        margin-bottom: 0px;
    }
    .cat-height{
        padding: 25px 0;
    }
    div.categoryCard.card{width:100%; box-shadow: 0 0 #000000;}
    .categoryCard{
        background-color: white;
        border-radius: 22px;
        box-shadow: 3px 7px 5px #e6eaed;
        padding: 55px 0px;
        margin: 0 10px;
    }
    .dcategoryImg{
        width: 80px;
    }
    .featured-shw-img{margin-right: 15px;}
    .catTitle{color: white; font-size: 12px; font-weight: 900; color: white; line-height: 1em; padding: 20px 0 20px 10px; text-align: left; background-color: #00000042;}
    .categoryItem{padding: 0; text-align: center;}
    .nav-tabs .nav-link{
        width: 49%; 
    }
    .nav-link{margin: 0px}
    .showDetails-content{border-top: 0;}
    .player-comment-btn {
        top: 0px;
    }

    /* Header CSS */
    .sticky-navbar{
        position: fixed;
        top: 0;
        z-index: 99;
        width: 100%;
    }
.dropdown > .dropdown-toggle {
    padding-top: 5px;
    /* display: none; */
}
.ProfileImg{
    color: white;
    box-shadow: none;
    background: white !important;
    margin: auto!important;
    width: auto !important;
    border-radius: 25px !important;
}
.btn-primary.dropdown-toggle {
    background-color: #fff !important;
}
    .search-sec button {border-radius: 35px !important; background-color:#eb1163 !important; margin-left: -95px !important;}
    .desktop-nav a span{
        font-family: 'BwModelica-Bold';
    }
    .main-menu a{
        margin: 0px 10px; 
        font-size: 14px;
        text-align: left;
        /* text-transform: uppercase; */
        vertical-align: middle;
        color: #1d1d1f;
        margin-top: auto;
    }
    bt.n-flat {background-color: #eb1163 !important; color: white !important;}
    .show-play-btn{ border-radius: 35px !important;}
    /* Body CSS */

    .channel-carousel{margin: 20px 5px;}
    .channel-carousel li{ width: 140px !important;}
    .sectionName{font-size: 20px; font-family: "BwModelica-Bold"; color: #1d1d1f; padding-top: 30px; letter-spacing: 0.5px; margin-bottom: 8px; padding-left: 15px;}

     .btn .btn-primary{background-color: #eb1163 !important; color: #fff !important;}
    .episode-title{font-size: 16px; color: #434343; font-weight: 700; line-height: 14.4px;}
   tr:hover td  {background:#fff ;}
   tr:hover > td > .desktop-epi-pause {
    /* display: block !important; */
}
.desktop-epi-pause i.fa-play-circle, .fa-pause-circle {font-size: 35px;}

tr > td > .desktop-epi-pause {
    /* display: none; */
}
.recommended-grid{
    width: calc(50% - 16px );
  box-shadow: 2px 2px 4px #ccc;
  border-radius: 6px;
  margin: 8px;
}
    .show-episode-list, td, table.table th, table.table td{margin: 0; padding: 12px 0px;;}
    table.table thead th{font-size: 12px; font-weight: 600; color: #000000; text-align: left; vertical-align: top;}
    .episode-description{font-size: 14px; color: #888888; font-weight: 500; padding-top: 4px;text-align: left;}
    .episode-description a{color: #eb1163 !important}
    .showTitle{font-size: 14px; text-align: left;color: black;}
    h4.showTitle:hover{color: black;}
    .channelTitle{font-size: 14px; text-align: center; color: black;}
    .titlen2{font-size: 12px; color: #888888; display: none;}
    .para{font-size: 12px; color: #888888;}
    .lang-card{width: 60% !important; margin-top: -45px;}
    .centerTxt{text-align: center !important;}
    thead th{font-size: 16px; font-family: "BwModelica-Bold";}
    /* .categoryItem{padding: 15px 70px;} */
/* 4. Footer CSS */
    .dash-epi-sec{width: 40px; }
.dfooter{ padding-top: 60px;}
.footer{background: #E6EAED; padding: 10px;display:none !important}
.footer-section{ border-bottom: 1px solid #CAD0D4;border-top: 1px solid #CAD0D4; padding: 30px 0;}
.footer-section1{ border-bottom: 1px solid #CAD0D4;border-top: 1px solid #CAD0D4; padding: 30px 0;}
.footer-section2{ border-bottom: 1px solid #CAD0D4;}
.footer-section3{padding-bottom: 30px;}
.client-title{text-align: center; font-size: 14px; font-family: 'BwModelica-Bold'; padding-bottom: 12px;}
.client-logo li{ margin: 0px 12px; line-height: 64px;}
.client-logo {text-align: center;}

.footer-title{color: #888888;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  }
  ul.footer-menu {padding-left: 0;}
  .footer-menu a:hover{color: #eb1163 !important;}
  .footer-menu a, .footer-menu li, .footer-menu span {color: #888888; font-size: 12px; display: inline-block; list-style: none; padding: 0 2px;}
  .social-menu li {color: #888888; font-size: 12px; display: inline-block; list-style: none; padding: 0 2px;}
  .footer-menu li::after {
    content: " | ";    
    color: #888888;
  }
.brand-name{font-family: 'BwModelica-Bold'; }
  .footer-menu li:last-child:after {
    /* content: "  ";  */
    display: none;
  } 
  .socio-sec{padding: 15px 0px 20px 0; width: 32px;}
  .copyright-sec{padding: 15px 0; text-align: right;}
  .socio-sec li{display: inline; }
  .col-centered{
    float: none;
    margin: 0 auto;
  }

  .social-btn{
    width: 30px;
  }
/* //End Footer CSS */

/* **********************
        Static Pages Css
**************************/
.cat_page-content{padding: 40px 0;}
.page-content{
    padding: 40px 0;
}
.page-header{
    font-family: "BwModelica-Bold";
    text-align: left !important; 
    font-size: 40px !important;
    margin: 20px 0;
}
.sub-header{
    font-family: 'BwModelica-Bold';
}
.page-link{
    font-family: 'BwModelica-Bold';
    font-size: 14px;
    color: #eb1163;
}
.header-border{
    /* border-bottom: 8px double #eb1163 ;
    width:100px; */
}
.page-para{
    font-family: 'BwModelica';
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #606060;
}
.page-para a{
    color: #eb1163;
}
.faq-questions{
    font-family: 'BwModelica-ExtraBold';
    font-size: 14px;
    color: #000;
}
.faq-rline{
    border-right: 2px dashed #eb1163;
}
/* About Page */



/**************************
        5. Show Page CSS
****************************/

a .category-txt{
    color: #888888;
    font-family: "BwModelica";
    font-size: 12px;
    margin-top: 0px;
}
a .category-txt:hover{
    color: #eb1163 !important;
}
.card-deck{
    box-shadow: 5px 7px 5px #ccc;
    border-radius: 8px;
}
.showDetails-style a{ color: #eb1163;}
.playnow-btn{
    background-color: #eb1163;
    color: #fff;
    border-radius: 25px;
    padding: 6px 16px;
    margin-right: 8px;
    width: 122px;
    height: 35px;
    cursor: pointer;}
.playnow-btn i{color: #fff; font-size: 12px; padding-right: 5px; position: relative;
    top: -2px;
    left: 3px;}

.playTxt{    position: relative;
    top: -2px;}
.season-btn{background-color: white; color: #888888; border-radius: 25px;
    padding: 8px 0px;
    padding-right: 6px;
    width: 122px;
    height: 35px;
    cursor: pointer;}
.season-btn i{ 
    color: #888888; 
    position: relative;
    top: 3px;
    right: -8px;}
.infoBtn{
    width: 20px;
    margin-top: -8px;
}
/* .subscribe-txt img{}  */
.subscribe-txt{width: 35px; height: 35px; margin: 0px 0 0 10px; position: relative; display: inline-block;}
.subscribe-txt .img-top{display: none;}
.subscribe-txt:hover .img-top{display: inline; position: relative; top: -35px; z-index: 99;}
/* Share button animate */
.share-txt{ margin: 0px 10px;position: relative; display: inline-block;  cursor: pointer;}
.share-txt img{width: 35px; height: 35px;}
.share-txt .img-top{display: none;}
.share-txt:hover .img-top{display: inline; position: relative; left: -35px; z-index: 99;}

/* .sub-icon{padding: 12px 0 0 2px;} */
.show-sub-section{margin: 16px 0;}
.show-description{text-align: left; margin-bottom: 40px;}
.d-show-description{text-align: left; margin-bottom: 40px; font-size: 14px; line-height: 20px;}
.show-des-title{ font-size: 16px; font-family: 'BwModelica-Bold'; padding-bottom: 16px;}


/**************************
        5. Show Page CSS End
****************************/ 

/* 3. HomePage CSS */
.search-sug-btn{margin: 6px;
    padding: 7px;
    border-radius: 25px;
    background-color: #d6d6d6;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;    cursor: pointer;}
.search-result-img{ width: 132px; padding: 2px 2px 2px 2px; border-radius: 8px;}
.search-box-showTitle{color: white; font-size: 16px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;   max-width: 120px;}
.gshow{margin: 0 10px;}
.preview-btn i {font-size: 8px;}
.preview-btn{border-radius: 35px;
    float: right;
    font-size: 12px;
    color: #eb1163;
    border: 1px solid #eb1163;
    width: 54px;
    max-width: 62px;}
.lang-txt{ font-size: 12px; color: #86868b; text-transform: capitalize; text-align: left; padding-left: 14px; padding-right: 14px;
    padding-bottom: 8px;}
.show-container li{ padding: 12px 0px 12px 5px;}
/* .show-container li:hover{background-color: white; border-radius: 15px;} */
/* .bannerv2 li{margin: 0; padding: 0;} */
/* .bannerv2 li:hover{border: 1px solid white; background-color: white; border-radius: 15px;} */
.playBtn i{display:none;  } 
.showTitle:hover .playBtn {display: block; position: absolute; right: 10px;}
.itemsContainer {
    margin: 10px 0 10px -3px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 6px 8px 11px #E6EAED;
    height: 70px;
    overflow: hidden;
    width: 96.4%;
}
.itemsContainer1 {
    border-radius: 10px;
}
.ditemsContainer1 {
    /* margin: 10px 5px; */
    /* background-color: white; */
    /* border-radius: 10px; */
    /* box-shadow: 6px 8px 11px #E6EAED; */
    cursor: pointer;
}
.card-text{}

.itemsContainer2 {
    margin: 10px 5px;
    /* background-color: white; */
    border-radius: 10px;
    /* box-shadow: 6px 8px 11px #E6EAED; */
}
/* .itemsContainer1:hover{box-shadow: 7px 5px 3px #ccc;}  */
.itemsContainer:hover .play i{display:block; background-color: #eb1163; color: white; padding: 8px; border-radius: 50%;}
h4.channelTitle{
    padding-top: 4px;
    padding-bottom: 4px;
    color: black;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    overflow: hidden;
    max-width: 200px;
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}
h4.category-title{
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 220px;
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 14px;
}
.itemsContainer1:hover{opacity: 0.6; background-color: #fff;}  
.ditemsContainer1:hover{opacity: 0.6; background-color: #fff;border-radius: 15px;}  
.itemsContainer1:hover .play1 i{display:block; background-color: rgba(0, 0, 0, 0.888); color: white; padding: 20px; border-radius: 50%;margin-left: 45px;margin-right: auto;}
.ditemsContainer1:hover .play1 i{display:block; background-color: rgba(0, 0, 0, 0.888); color: white; padding: 20px; border-radius: 50%;margin-left: 45px;margin-right: auto;}
.play i{
  position : absolute;
    display:none;
    margin:0 auto; 
    top: 30%; 
    /* left:0px; */
    right:35px;
    z-index:100;
    font-size: 14px;
    color: white;
} 
.play1 i{
    position : absolute;
      display:none;
      top: 28%;
      right: 36%;
      z-index:100;
      font-size: 21px;
      color: white;
  } 
.RecshowTitle{
    padding: 8px 14px;
    font-family: "BwModelica";
    color: #1d1d1f;
    overflow: hidden;
    max-width: 200px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.showDetailName{
    font-weight: 700;
}
  .dshowTitle{
    padding-top: 4px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: "BwModelica";
    color: #1d1d1f;
    overflow: hidden;
    max-width: 200px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dshowImg{border-radius: 10px 10px 0 0 !important;}
.pshowImg{border-radius: 10px; width: 215px; cursor: pointer;}
.pwrapper{width: 80% !important;}
.cl-prog-bar1{
    border: 3px solid #5ab572;
    background: #5ab572;
    position: relative;
    bottom: 7px;
    width: 95%;
}
.cl-prog-bar2{
    border: 3px solid #5ab572;
    background: #5ab572;
    position: relative;
    bottom: 7px;
    width: 20%;
}
.cl-prog-bar3{
    border: 3px solid #5ab572;
    background: #5ab572;
    position: relative;
    bottom: 7px;
    width: 45%;
}
.showImgCard{ border-radius: 10px; height: 100%;}
.dshowCard{height: 100%;}
.showImgCard img{ border-radius: 10px 10px 0 0;}

.slick-arrow img{display: none;}
.CatBody{background-color: #f5f5f7;}
.catName{color: black; font-family: 'BwModelica-Bold';}
.des-showImgCard{ border-radius: 8px; padding: 0px; }
.des-showImgCard img{padding: 0; margin: 0;}
.hover-effect{margin: 14px 12px; border-radius: 10px;}
.hover-effect:hover{box-shadow: 0px 2px 10px 2px #dee3e7;  }
.hover-effect .card-text span:hover { background-color: #fff !important; border-radius: 0 0 10px 10px !important;}

/* Miscellaneous Css */
    .navbar-brand img {
        width: 95px;
        margin-top: 8px;
    }
    .margin-0{margin: 5px; padding: 0;}
    .navbar{box-shadow: none !important;}
    .live-gif {width: 60px}
    .more-nav {width: 20px;}
    span.live-btn:hover{color: #eb1163;}
    .live-btn{background-color: #eb1163; color: white !important; padding: 2px 12px 2px 12px; border-radius: 5px; }
    div.footer-nav.nav{display: none;}
    .slideImage{width: 300px; border-radius: 8px !important;}
    div.showCard.card{width: 180px !important; border-radius: 8px !important;}
    .wrapper .showItem{width: 180px !important;}
    .episodePic{width: 60px !important}
    
.CatBody
    /* PLayer Css */
    .episode-img{width: 350px;}
    .my-p1{margin: 5px 0;}
    /* .audioPlayer{position: fixed; display: block; bottom: 80px; left: 0px;} */
    .episode-img{
        width: 280px;
        margin : 30px 0;
    }
    .pd-top1{padding-top: 40px !important;}
    .pd-top2{padding-top: 35px !important;}
    .pd-top3{padding-top: 36px !important;}
    .recommend-hover-effect:hover{
        border-radius: 10px;
    }
    .sidebar-section{padding-bottom: 30px;}
    .recommend-hover-effect{background-color: white; margin: 12px; padding: 0px; border-radius: 10px;}
    .recommend-showImg{width: 100%; border-radius: 10px 10px 0 0;}
    .epi-img{width: 80px; border-radius: 3px; margin: 0px 11px -4px 11px; cursor: pointer 
        ;}
    .episode-title{margin : 10px 0; cursor: pointer;}
    .showItem{padding-top: 16px;}
    .player-grid{ width: 60%; border-right: 1px solid #f0eeee; height: 100%; margin-top: 30px;}
    
    .playlist-item{box-shadow: none !important; border-bottom: 1px solid #E6EAED !important;}
    .social-links{margin : 10px}
    /* .player-like-btn{ width: 20px;} */
    .player-like-btn{ width: 30px !important; position: absolute !important; top: 25px !important; right: 30px !important;}
    /* .audioPlayer{
        position: fixed !important;
        display: block !important;
        bottom: 54px !important;
        left: 0px !important;
        padding: 0px 22px !important;
        margin: 0 !important;
    } */

    /* =========================
        Section CSS
       ======= ============= */
       
       /* .react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right{
        top: calc(40% - 10px);
    }
   
        .react-multiple-carousel__arrow--right {
            right: calc(-0.1% + 0px) !important;
            }
       .react-multiple-carousel__arrow::before{font-size: 11px !important; color: black;}
       
       .react-multiple-carousel__arrow{
            position: absolute;
            outline: 0;
            transition: all .5s;
            border-radius: 50px;
            z-index: 1000;
            border: 0;
            background: #dde1e5  !important;
            min-width: 25px  !important;
            min-height: 28px  !important;
            opacity: 1;
            box-shadow: 3px 3px 3px #ccc;
        } */
        .react-multiple-carousel__arrow--left {
            left: calc(1% + 0px) !important;
        }
        .react-multiple-carousel__arrow--right {
            right: calc(1% + 0px) !important;
        }
        .react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right {
            top: calc(47% - 10px);
        }
        .react-multiple-carousel__arrow::before{font-size: 12px !important; color: #000;}
        .react-multiple-carousel__arrow{min-width: 30px !important; min-height: 30px!important; background-color: #808080bd;}
        .slick-prev:before, .slick-next:before{color: #888888 !important; top:50% !important; opacity: 1; font-size: 28px !important;}

        .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
            opacity: 1 !important;
            color: #888888 !important;
        }
        /* .react-multiple-carousel__arrow:hover{} */
         .slick-next, .slick-prev{
            top: 50% !important;
            /* width: 80px !important; */
            /* height: 80px !important; */
            opacity: 0.6;
        }
        .slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover{opacity: 1;}
        /* .slick-prev{left: -10px !important; z-index: 2;}
        .slick-next{ right: -10px !important;} */

/*******************************
        Live Show Page
********************************/

.live-show-grid{padding: 14px; border-radius: 10px;margin: 7px}
.live-show-img{border-radius: 10px; width: 100%;}
.live-show-title{font-family: 'BwModelica-Bold'; font-size: 20px;}
.live-show-description{font-size: 15px; padding-top: 8px; color: #888; text-align: left;}
.table-borderless{text-align: center; }
tr.live-timer td{background-color: #e6e9ee; border-radius: 15px; border: 6px solid white; width: 40px;text-align: center;}
.live-timer-days td{margin-right: 50px !important;text-align: center;}
/* .live-timer-hours{margin-right: 50px !important;} */
.schedule-box{ background-color: #452d79; padding: 4px;  border-radius: 3px; color: white; margin-top: 10px; }
.time-section{
    background-color: #eb1163;
    padding: 7px 30px;
    border-radius: 4px;
    margin-right: 16px;
    margin-left: -5px;
}
.reminder-btn{background-color: #888888; border-radius: 3px; text-align: center; color: white; text-transform: uppercase; border: none; font-weight: 600; padding: 4px 20px;}
.live-join-btn{background-color: #eb1163; border-radius: 3px; text-align: center; color: white; text-transform: uppercase; border: none; font-weight: 600; padding: 4px 20px;}
.date-section{}
.scroll-to-top svg{float: right; position: relative; right: 0;}
}
  
/* Tablet CSS */
@media only screen and (min-width:321px) and (max-width:768px) {
    .MainContainer{
        width:100%; 
        }
        .itemsContainer1:hover .play1 i{ display:none ;}
        
    .channelContainer {
    width: 83% !important;
    height: auto !important;
    padding: 0px 0px 0px 50px !important;
    margin-left: 15% !important;
    position: relative !important;
    top: -54px !important;
}
.left-arrow {
    display: none;
}
.sub-head {
    /* padding: 5px 5px; */
    margin-top: 8px !important;
}
.dchannelImg {
    width: 70% !important;
}
 .vsearch-btn{
    right: -19px !important;
 z-index: 1
}
}

body{
    margin: 0;
}
h1{margin: 0; font-family: "BwModelica-Bold";}

#lpheader{
    background-color: #fe3c00;
    height: 550px;
    width: 100%;
}
.lp-logo{
    width: 140px;
}
.para-reg{
    font-family: "BwModelica";
  }
.para-bold{
font-family: "BwModelica-Bold";
}
.banner-heading{
    color: white;
    font-family: "BwModelica-ExtraBold";
    font-size: 75px;
}
.banner-para{
    font-family: "BwModelica-Light";
    font-size: 35px;
    color: white;
    text-align: left;
}
.banner-btn{
    width: 165px;
    /* box-shadow: 3px 5px 3px #ededed;     */
}
.banner-img{
    width: 480px;
    margin-top: -80px;
}
/* Section Leaders */
#leaders{background-color: white;}
.content{
    padding: 80px 0px;
}
.title-orange{
    font-family: 'Dans';
    font-size: 65px;
    color: #fe3c00;
    text-align: center;
}
.client-img {text-align: center;}

/* Section Quote  */
#quote{background-color: #f3f6f9;}
.sub-title{
    font-family: 'BwModelica';
    font-size: 35px;
    text-align: center;
}
.ppl-img{
    margin: auto;
    display: block;
}
.lpauthor-name{
    font-size: 20px;
    color: #fe3c00;
    text-align: center;
    font-family: 'BwModelica-Bold';
    text-transform: uppercase;
}
.author-bio{
    font-size: 20px;
    color: #000;
    text-align: center;
    font-family: 'BwModelica-Bold';
}
/* player section */
#player{
    background-color: white;
}
.episode-head-title{
    font-size: 18px;
    font-family: 'BwModelica';

}
.episode-head-title2{
    padding-left: 15px;
    font-size: 18px;
    font-family: 'BwModelica';

}

.episode-section{padding-top: 25px;}
.feature-episode-section{padding-top: 25px}

.fepisode-list{
    height: 140px;
}
.lpepisode-list{
    height: 70px;
}
.lpepisode-content{padding: 15px 0px; text-align: left;}
.lpepisode-description{
    text-align: left;
    font-family: 'BwModelica-Light';
    color: black;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.lpepisode-name{
    font-family: 'BwModelica-Bold';
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.more-link{
    cursor: pointer;
    color: #fe3c00; 
    font-family: 'BwModelica-Bold';
    font-size: 12px;
}
.more-link:after{
    cursor: pointer;
    color: #fe3c00; 
    font-family: 'BwModelica-Bold';
    font-size: 12px;
}
.lpepisode-img img{
    width: 120px;
    text-align: left;
}

/* All Episode */
tr{
    text-align: left;
}
.epi-img{
    width: 65px;
}
.epi-play-icon{
    width: 40px;
    vertical-align: middle;
}
.info-icon{
    width: 30px;
    vertical-align: middle;
}

/* hover effect */
.vid-clip-sm {
    width: 120px;
    height: 120px;
    position: relative;
    margin: 0 8px 8px 0;
    float: left;
  }
  
  .vid-clip-sm .vid-clip-play {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition-property: background-color;
    transition-duration: 0.4s;
  }
  
  .vid-clip-sm .vid-clip-play {
    background-color: rgba(0, 0, 0, 0.3);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#66000000', endColorstr='#66000000'); /* IE */
  
  }
  
  .vid-clip-sm .vid-clip-play img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -22px;
    width: 54px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    cursor: pointer;
  }

  .vid-clip-sm .vid-clip-play img:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

/* Section Reasons */
#reasons{background-color: #f3f6f9;}
.title-orange span{
    font-family: 'BwModelica-Bold';
    font-size: 38px;
    color: black;
    text-align: center;
}
.reason-header{
    text-align: center;
    margin-bottom: 40px;
}
.reason-border{width: 100%;}
.reason-head2{
    font-family: 'BwModelica-ExtraBold';
    font-size: 18px;
    color: black;
    margin-bottom: 15px;
}
.reason-no-point{
    background-color: #fe3c00;
    padding: 10px;
    border-radius: 50%;
    color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    font-family: 'BwModelica-Bold';
    margin-top: -36px;
    margin-bottom: 30px;
}
.reason-description{
    text-align: left;
    font-family: 'BwModelica';
    color: black;
    font-size: 14px;
}
/* Host Section  */
#host{
    background-color: #fe3c00;
    padding-top: 80px;
}
.host-title{
    font-size: 18px;
    color: white;
}
.host-name{
    font-family: 'BwModelica-ExtraBold';
    color: white;
    font-size: 38px;
    padding-top: 10px;
}
.host-img{
    width: 450px;
}
.social-host{
    text-align: left; 
    padding-top: 10px;
}
.social-host img{
    margin-right: 15px;
    width: 20px;
}
.host-description{
    color: white;
    font-family: 'BwModelica';
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}
/* About Section */
#about{
    padding: 80px 324px;
    line-height: 30px;
}
.lp-about-para{
    text-align: center;
    font-size: 16px;
    color: black;
}
.strong-txt{
    font-family: 'BwModelica-Bold';
}
/* Section Contact */
#contact{
    background: #f3f6f9;
}
.header-contact{
    font-family: 'BwModelica-ExtraBold';
    font-size: 38px;
    color: black;
    text-align: center;
}
.contact-form{
    width: 60%;
    margin: auto;
}
.contact-form input{
    border-radius: 35px;
    border: 2px solid #dcdedf;
    width: 100%;
    margin: 10px;
    padding: 12px 21px;
}
.contact-form button{
    background: #fe3c00;
    color: white;
    border: 1px solid #fe3c00;
    border-radius: 35px;
    padding: 6px 20px;
    margin: auto;
    display: block;
    margin-top: 10px;
}
/* Footer Section */
#footer{
    background: white;
}
.footer-dark-logo{
    width: 180px;
    display: block;
    margin: auto;
}

/*  Dropdown css */
.info-icon:after {
  cursor: pointer;
  color: #FCC;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdownLanding {
   position: absolute;
    right: 20px;
    /* bottom:10px; */
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 400px;

}
.dropdownLanding >div {
    display: flex;
   padding: 7px;
    align-items: center;
}
.dropdownLanding >div >i {
   margin-right: 12px;
}

.dropdownLanding-container:focus {
  outline: none;
}

.dropdownLanding-container:focus .dropdownLanding {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}
/*  feature dropdown */

.dropdownLandingFeature {
   position: absolute;
    right: 10px;
    /* top:0px; */
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    /* width: 400px; */

}
.dropdownLandingFeature >div {
    display: flex;
   padding: 7px;
    align-items: center;
}
.dropdownLandingFeature >div >i {
   margin-right: 12px;
}

.dropdownLanding-container-feature:focus {
  outline: none;
}

.dropdownLanding-container-feature:focus .dropdownLandingFeature {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}
@font-face {
  font-family: "BwModelica-Black";
  src: local('BwModelica'), url(/static/media/BwModelica-Black.9d2373b5.woff) format('woff');
}

@font-face {
  font-family: "BwModelica-Bold";
  src: local('BwModelica'), url(/static/media/BwModelica-Bold.538323b4.woff) format('woff');
}

@font-face {
  font-family: "BwModelica-ExtraBold";
  src: local('BwModelica'), url(/static/media/BwModelica-ExtraBold.67f72076.woff) format('woff');
}

@font-face {
  font-family: "BwModelica-Hairline";
  src: local('BwModelica'), url(/static/media/BwModelica-Hairline.6f252da0.woff) format('woff');
}

@font-face {
  font-family: "BwModelica-Light";
  src: local('BwModelica'), url(/static/media/BwModelica-Light.ab425f05.woff) format('woff');
}

@font-face {
  font-family: "BwModelica-Medium";
  src: local('BwModelica'), url(/static/media/BwModelica-Medium.99b10917.woff) format('woff');
}

@font-face {
  font-family: "BwModelica";
  src: local('BwModelica'), url(/static/media/BwModelica-Regular.d700f9b0.woff) format('woff');
}

@font-face {
  font-family: "Dans";
  src: local('Dans'), url(/static/media/DansHandWriting.b94f8ca1.ttf) format('woff');
}
.list{
    margin-left: 7rem;
    
}

.onactive:hover{
    background-color: rgb(243, 208, 214);
}
.onactive:active{
    background-color: rgb(240, 53, 84);
}
.onactive:focus{
    background-color: rgb(248, 95, 120);
    color: white;
}
.onactive{
    margin: 2px;
    box-sizing: border-box;
}
.shows_liveShwSubtitle__20IPZ{
    overflow: hidden;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    text-align: left;        
    max-width: 175px;
}
.shows_ppbutton4__2HuUM{color: #ec116e;}
.shows_liveBtn__3t954{    background-color: #eb1163;
    color: white;
    border-radius: 35px;
    font-family: 'Mukta' !important;
    padding: 8px 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px !important;
    border: none;
}
/* Mobile CSS */
@media only screen and (max-width: 768px){
    .shows_show-img__1W9tH{height: 150px; width: 100%;}
    .shows_emoji-sec__33wd- img{ margin-left: 3px; } 
    .shows_desktop-only__3oHAV{display: none;}                                       
    .shows_brand-wrap__2LtFJ a{display: none;}
    .shows_login-content__2k5Z7{padding: 10px 15px; text-align: center;}
    .shows_live-bar__1_3pK{    position: absolute;
      top: 50%;
      left: 6%;
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;}
  .shows_live-show-title__2lI0q{font-size: 12px;}
  .shows_card-body__z6FeB{padding: 0;}
  .shows_m-tspace__Ru4xy{padding-top: 15px;}
  .shows_send-btn__31G6A{
      background: none;
      border: none;
      float: right;
  }
  .shows_send-btn__31G6A img{ 
      width: 40px;
      position: absolute;
      right: 12px;
      bottom: 9px;
  }
  .shows_brand-logo__PrsWI{width: 120px;}
  .shows_liveShowImg__3XhRK {
    width: 100px;
border-radius: 15px 0 0 15px;
float: left;
margin-right: 12px;
}
.shows_liveContent__3w313{
    /* padding: 30px 15px; */
    line-height: 16px;
}
.shows_liveShwTitle__3v9Rl{
    font-size: 14px;
    font-weight: 600;
    
}
.shows_showGrid__1qsjL{
    box-shadow: 3px 5px 7px #ccc;
    border-radius: 15px;
    /* margin: 20px 15px; */
    padding: 0;
    display: flex
    
  }
  }
  
  
  /* COmmon CSS */
  .shows_input-group-text__2TNfU:focus{
      outline: none;
  }
  .shows_input-group-text__2TNfU{font-size: 12px; color: #ec116e; background-color: #FFF8FB; border: 1px solid #FFF8FB; border-radius: 35px; height: 40px; margin-top: 8px; border: 0px solid;}
  .shows_form-control__blTGN{border-radius: 35px; background-color: #FFF8FB; text-align: left; padding: 8px 0px; width: 100%; border: 0px solid; margin: 8px 0;}
  /* COmmon CSS */
  /* Desktop CSS */
  @media only screen and (min-width: 768px) {
   
    /* New UI */
        .shows_emoji-sec__33wd- img{ margin: 5px; cursor: pointer;}
        .shows_emoji-sec__33wd- i{ cursor: pointer;}
    /* New UI */
    .shows_liveBroImg__3qYtE{
        min-width: 120px;    
        width:200px  
          }
    .shows_BroGrid__mAIeQ{
        box-shadow: 3px 5px 7px #ccc;
        border-radius: 15px;
        /* margin: 20px 15px; */
        padding: 0;
     text-align: center;
    }
      .shows_showGrid__1qsjL{
          box-shadow: 3px 5px 7px #ccc;
          border-radius: 15px;
          /* margin: 20px 15px; */
          padding: 0;
          display: flex;
          max-height: 205px;
        }
        .shows_liveShwTitle__3v9Rl{
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 15px;
        }
       
    .shows_liveShowImg__3XhRK {
    border-radius: 15px 0 0 15px;
    float: left;
    margin-right:12px;
    }
      .shows_send-btn__31G6A img{ 
          width: 40px;
          position: absolute;
          right: 12px;
          bottom: 9px;
      }
      .shows_send-btn__31G6A{
          background: none;
          border: none;
          float: right;
      }
      .shows_text-box__1eJsO{
          width: 390px;
          height: 230px;
          overflow-y:scroll;
          overflow-x: hidden;
      }
      .shows_login-content__2k5Z7{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 450px;
          text-align: center;
      
      }
      .shows_show-name__1D001{font-size: 16px; font-weight: 600;}
      .shows_show-card__o9WuS{width: -webkit-fit-content;width: -moz-fit-content;width: fit-content;
          /* margin: 40px,0,0,40px; */
          margin-left: 40px;
          margin-right: 40px;
      }
      .shows_live-icon__2pSUa{width: 15px;}
      .shows_live-bar__1_3pK{position: absolute; bottom: 35%; color: white; text-transform: uppercase; font-weight: 600; left: 10px;}
      .shows_live-show-title__2lI0q{font-size: 12px;}
      /* .live-shw-icons i{padding: 0 2px;} */
  
  
      .shows_card-body__z6FeB{background-color: #FFF8FB;padding: 10px; border-radius: 0 0 20px 20px; box-shadow: 3px 7px 5px #ccc;}
      .shows_card-img-top__2Ux-R{ background-position: center center;
          background-repeat: no-repeat; width: 300px; height: 220px; border-radius: 20px 20px 0 0;}
     .shows_brand-logo__PrsWI{width: 180px; display: block; padding-bottom: 10px;}
     .shows_brand-wrap__2LtFJ{font-size: 12px; font-weight: 600; }
    
     .shows_brand-wrap__2LtFJ a{margin: 15px; color: #617d89; text-decoration: none;}
     .shows_brand-wrap__2LtFJ a:active, .shows_brand-wrap__2LtFJ a:hover, .shows_brand-wrap__2LtFJ a:focus{color: #ec116e;}
     .shows_brand-wrap__2LtFJ i{padding-right: 5px; font-size: 14px;}
      /* .logo{width: 180px;} */
     
     
      /* .login-txt{font-size: 13px; text-align: ;} */
      /* .login-input-txt{border-radius: 50%; background-color: lightpink; padding: 10px ;}
      .login-btn{border-radius: 50%; background-color: #ec116e; } */
  }
  input:focus{
      outline: none;
  }
  .shows_live-icon__2pSUa{width: 15px;}
  .shows_login-form__2DI9B{ width: 100%;}
  .shows_login-input-txt__1gm9h{border-radius: 35px; background-color: #FFF8FB; text-align: center; padding: 8px 0px; width: 100%; border: 0px solid; margin: 8px 0;}
  .shows_login-btn__2AwBv{border-radius: 35px; background-color: #ec116e; border: 0; color: white; font-size: 12px; font-weight: 500; width: 100%; padding: 12px;}
  .shows_goliveBtn__2X119{background-color: #ec116e; border-radius: 35px; border: white; color: white;padding: 8px 12px; text-transform: uppercase; font-size: 12px; font-weight: 600;
    margin-right: 12px}
  .shows_logo__1Lw9y{width: 140px; padding-bottom: 20px;}
  .shows_pause-btn__3hjs2{width: 30px; float: right;}
  .shows_live-msg-input__3gp7h{border-radius: 35px; background-color: #fff; border: 1px solid black; text-align: left; padding: 8px 15px; width: 90vw; margin-bottom: 8px;}
  .shows_live-msg-sec__1AjUB{position: fixed; bottom: 0;margin-top: 12px;

}
div.userLogin p{
    text-align: center;
    font-size: medium;
}
.loginLogo{
    margin: auto;
    display: block;
    width: 220px;
}
