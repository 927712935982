/* .slideImage {
  width: 100%;
  height: 450px;
}
.channelCard {
  border-radius: 150px !important;
}
.channelImg {
  border-radius: 150px !important;
}
.channelTitle{
font-size: 12px;
font-weight: 450;
text-align: center;
} */

/* Home Sections CSS */

body * {
  margin: 0;
}
.font-face-bw-reg{
  font-family: "BwModelica";
}
.font-face-bw-bold{
  font-family: "BwModelica-Bold";
}
/* Footer Css*/
html, body {
  /* max-width: 100%; */
  /* overflow-x: hidden; */
}
.footer-nav i{margin-top: 12px; font-size: 14px;}
.footer-nav a, p {
  color: #606060;
  text-align: center;
  font-size: 11px;
  padding: 0px 0 5px 0px;
}
.main{
  position: relative;
  overflow: scroll;
  /* margin-bottom: %; */
}
.nav-item{
  text-align: center;
  display: flex;
 width: 100px;

}
/* .footer-nav i {
  font-size: 20px;
} */
.footer-nav a, i{
  color: deeppink;
}

i.fa-play-circle, .fa-pause-circle{
  font-size: 20px;
}